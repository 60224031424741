<template>
  <div>
    <el-row style="padding-left: 10px">
      <el-button type="primary" @click="addOpen">添加款式类型参数</el-button>
    </el-row>
    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="styleTypeArray"
          border
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          max-height="700"
          style="width: 100%;">
        <el-table-column label="款式类型" align="center" #default="scope">
          {{scope.row.shortTypeName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column prop="typeName" label="类型全称" align="center"></el-table-column>
        <el-table-column label="是否激活" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="显示方式" align="center">
          <template #default="scope">
            <el-switch
                @change="showTypeChange(scope.row)"
                v-model="scope.row.showType"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="全称"
                inactive-text="类型">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="editOpen(scope.row)" v-if="scope.row.isDefault!==1">修改</el-button>
              <el-button type="danger" size="mini" @click="delStyleType(scope.row)" v-if="scope.row.isDefault!==1">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <style-type-edit v-if="editState" :state="editState" :styleType="styleType" @close="editClose"></style-type-edit>
    <style-type-add v-if="addState" :state="addState" @close="addClose"></style-type-add>
  </div>
</template>

<script>
import styleTypeEdit from "@/pages/style/style-type/style-type-edit"
import styleTypeAdd from "@/pages/style/style-type/style-type-add"
export default {
  name: "style-type-list",
  components: {
    styleTypeEdit,
    styleTypeAdd
  },
  created() {
    this.queryStyleTypeArray()
  },
  data() {
    return {
      styleTypeArray: [],
      total: 0,
      page: 1,
      limit: 100,
      addState: false,
      editState: false,
      styleType: {},
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  methods: {
    queryStyleTypeArray() {
      this.$axios({
        method: "GET",
        url: "/styleType/styleTypeList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.styleTypeArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryStyleTypeArray();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryStyleTypeArray()
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/styleType/updateStyleType",
        params: {
          id: val.id,
          isValid: val.isValid,
          tenantCrop: localStorage.getItem("tenantCrop"),
          isDefault: val.isDefault
        }
      }).then(response =>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.queryStyleTypeArray()
        }
      })
    },
    showTypeChange(val) {
      this.$axios({
        method: "POST",
        url: "/styleType/updateStyleType",
        params: {
          id: val.id,
          showType: val.showType,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response =>{
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.queryStyleTypeArray()
        }
      })
    },
    delStyleType(val){
      console.log(val)
      this.$confirm('<p style="color: red">此操作将删除该款式类型下所有款式和婚纱等数据,是否继续?</p>', '删除', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/styleType/delById",
          data: {
            id: val.id,
          }
        }).then(response =>{
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? "删除成功" :response.data.msg,
            type: flag? 'success' :'error',
            duration: 1000,
          })
          if (flag) {
            this.queryStyleTypeArray()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    editOpen(val) {
      this.styleType = val
      this.editState = true
    },
    editClose() {
      this.editState = false
      this.queryStyleTypeArray()
    },
    addOpen() {
      this.addState = true
    },
    addClose() {
      this.addState = false
      this.queryStyleTypeArray()
    }
  },
}
</script>

<style scoped>
</style>