<template>
  <el-dialog
      title="标签类型添加"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="款式类型" prop="typeName">
            <el-input v-model="form.shortTypeName" placeholder="款式类型 如: H L Z"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="类型全称" prop="shortTypeName">
            <el-input v-model="form.typeName" placeholder="类型全称 如:婚纱 礼服 中式"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "style-type-add",
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      states: this.state,
      form: {
        typeName: "",
        shortTypeName: "",
      },
      rules: {
        typeName: [{required: true, message: '请填写款式类型', trigger: 'blur'}],
        shortTypeName: [{required: true, message: '请填写类型简称', trigger: 'blur'}],
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/styleType/saveStyleType",
            params: {
              isValid: 1,
              ...this.form,
              tenantCrop: localStorage.getItem("tenantCrop")
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "添加成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>